






















import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import TelegramProfileButton from "@/components/TelegramProfileButton.vue";

@Component({
  components: { TelegramProfileButton }
})
export default class TableUserActionButtons extends Vue {
  @Prop() id!:number | undefined | null

  @Prop() login!:string | undefined | null

  gotoUserProfile():void {
    this.$router.push({
      name: 'user_info',
      params: {
        [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
        userId: this.id!.toString()
      }
    })
  }
}
