




















import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import { tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";

import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  methods: {
    tgLoginLink
  },
  components: {
    Icon
  }
})
export default class TelegramLikeButton extends Vue {
  @Prop() userLogin!: string | undefined | null
}
