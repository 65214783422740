var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pi-table-header flex flex-col gap-2 md:flex-row md:items-center justify-between flex-wrap"},[_c('div',{staticClass:"flex flex-1 items-center"},[_c('h3',{staticClass:"pi-table-header__title mb-0"},[_vm._v(" "+_vm._s(_vm.tableTitle)+" ")]),_c('div',{staticClass:"pi-table-extra ml-auto"},[_vm._t("header-extra")],2)]),(_vm.hasSearch)?_c('search-text-input',{staticClass:"pi-table-header__search mt-0 mb-0 flex-1",attrs:{"setup":{
      'func': _vm.newConfigInputSetup,
      'args': {
        'model': this,
        'key': 'search',
        'titleVisible': false,
        'allowClear': true
      },
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }