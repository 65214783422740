import Api from "./Api";

export default class ReputationAPI extends Api {
	// Example:
	//  https://cabinet.chatkeeper.info/static/reputation/data-1001419771998.json
	static getFileUrl(statAccessKey = "") {
		return `${ this.BASE_URL }/static/privatereputation/data${ statAccessKey }.json`;
	}

}
