




























import { InputSetups } from "@/mixins/input-setups";

import SearchTextInput from 'piramis-base-components/src/components/Pi/fields/SearchTextInput/SearchTextInput.vue';

import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';

@Component({
  components: {
    SearchTextInput
  }
})
export default class TableHeader extends Mixins(InputSetups) {
  @Prop({ type: String, required: false, default: '' }) tableTitle!: string

  @PropSync('searchModel', { type: String, required: false }) search!: string

  @Prop({ type: Boolean, required: false, default: true }) hasSearch!: boolean
}
