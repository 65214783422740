















import VuReputation from "@/components/chat/reputation/VuReputation.vue";
import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { Vue, Component } from 'vue-property-decorator'

@Component({
  'components': {
    NotAvailableOptionsOverlay,
    VuReputation,
    PageTitle
  },
})
export default class ReputationChart extends Vue {
  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
